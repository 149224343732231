<template>
  <div class="item-container">
    <v-app>
      <v-dialog
        v-model="loginModal"
        max-width="500px"
        :persistent="true"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Change Password</span>
          </v-card-title>
          <b-container>
            <v-row>
              <input type="hidden" v-model="id">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="password"
                  label="New password"
                  name="Extend"
                  type="password"
                  v-validate="'required'"
                  outlined
                  dense
                ></v-text-field>
                <span style="color: red;"
                  v-show="errors.has('Extend')">{{ errors.first('Extend') }}</span>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="confirmpassword"
                  label="confirm password"
                  name="confirm password"
                  type="password"
                  v-validate="'required'"
                  outlined
                  dense
                ></v-text-field>
                <span style="color: red;"
                  v-show="errors.has('confirm password')">{{ errors.first('confirm password') }}</span>
              </v-col>
            </v-row>
          </b-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="change_password"
              style="text-transform: none;"
              outlined
            >
              Change
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            step="1"
          >
            Parents information
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="e1 > 2"
            step="2"
          >
            Secondary Studies
          </v-stepper-step>

          <v-divider></v-divider>
          <v-stepper-step
            :complete="e1 > 3"
            step="2"
          >
            Student Documents
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            Student choice
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card
              class="mb-12"
              color="white"
            >
              <!--    <img src="@/assets/icons/1.jpg" alt="Logo" class="logo">-->
              <v-card>
                <v-row>
                  <v-col cols="4" style="background-color: springgreen; margin: 0;">
                    <img src="@/assets/icons/3.png" style="width: 100%; height: 75%; margin-top: 70px;">
                  </v-col>
                  <v-col cols="5" style="margin-top: 40px;">
                    <center><img src="@/assets/icons/1.jpg" style="width: 100px; height: 100px;"></center>
                    <div v-if="isVisible == 'appear'">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field label="Father's names" dense outlined v-model="parentInfo.father_names" name="Father name" v-validate="'required'" readonly></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Father name') }}</span>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Mother's names" dense outlined v-model="parentInfo.mother_names" v-validate="'required'" name="Mother names" readonly></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Mother names') }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field label="District" dense outlined  v-model="parentInfo.district" v-validate="'required'" name="District" readonly></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('District') }}</span>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Territory" dense outlined v-model="parentInfo.territory" v-validate="'required'" name="Territory" readonly></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Territory') }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field label="Province" dense outlined v-model="parentInfo.province" v-validate="'required'" name="Province" readonly></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Province') }}</span>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Guardian phone number" dense outlined v-model="parentInfo.parent_phone" name="Guardian phone" readonly></v-text-field>
                        </v-col>
                      </v-row>
                      <v-text-field label="Guardian Id number" dense outlined v-model="parentInfo.idnumber" name="Guardian Identity" readonly><span style="color: red;">*</span></v-text-field>
                    </div>
                    <div v-if="isVisible !== 'appear'">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field label="Father names" dense outlined v-model="father" name="Father name" v-validate="'required'"></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Father name') }}</span>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Mother names" dense outlined v-model="mother" v-validate="'required'" name="Mother names"></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Mother names') }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field label="District" dense outlined  v-model="district" v-validate="'required'" name="District"></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('District') }}</span>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Territory" dense outlined v-model="territory" v-validate="'required'" name="Territory"></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Territory') }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field label="Province" dense outlined v-model="province" v-validate="'required'" name="Province"></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first('Province') }}</span>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field label="Parent phone number" dense outlined v-model="phone" name="Guardian's phone" v-validate="'required|numeric'"></v-text-field>
                          <span style="color: red;" v-if="valid">{{ errors.first("Guardian's phone") }}</span>
                        </v-col>
                      </v-row>
                      <v-text-field label="Parent Id number" dense outlined v-model="idNumber" name="Parent's Identity" v-validate="'required|numeric'"></v-text-field>
                      <span style="color: red;" v-if="valid">{{ errors.first("Parent's Identity") }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
            <span><i class="fa fa-spinner" style="color: #0e4ac4;" v-if="sendingMsg"></i></span>
            <v-btn
              class="btn-next"
              @click="info"
              color="primary"
            >
              >>
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <img src="@/assets/icons/1.jpg" alt="Logo" class="logo">
            <v-card>
              <v-row>
                <v-col cols="4" style="background-color: springgreen; margin: 0;">
                  <img src="@/assets/icons/step2.png" style="width: 100%; height: 75%; margin-top: 70px;">
                </v-col>
                <v-col cols="7" style="margin-top: 40px;">
                  <center><img src="@/assets/icons/1.jpg" style="width: 100px; height: 100px;"></center>
                  <v-row style="margin-left: 20px !important;">
                    <v-row v-if="studyVisible ==='studyVisible'">
                      <v-col cols="6">
                        <v-text-field label="Diploma number" dense outlined v-model="studydata.diploma_number" name="Diploma" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Place of issue" dense outlined v-model="studydata.issued" name="place of issue" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Diploma number" dense outlined v-model="studydata.issue_date" name="Diploma" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Province" dense outlined v-model="studydata.province" name="place of issue" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="School name" dense outlined v-model="studydata.school_name" name="Diploma" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Section" dense outlined v-model="studydata.section" name="place of issue" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Option" dense outlined v-model="studydata.options" name="Diploma" readonly>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Code" dense outlined v-model="studydata.code" name="place of issue" readonly>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-row v-if="studyVisible !== 'studyVisible'" style="margin-left: 20px !important;">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field label="Diploma number" dense outlined v-model="diploma" v-validate="'required|numeric'" name="Diploma" >
                        </v-text-field>
                      </v-col>
                      <v-col cols6>
                        <v-text-field label="Place of issue" dense outlined v-model="issued" v-validate="'required'" name="place of issue">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-menu
                          v-model="menu3"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="issueDate"
                              label="date of Issue"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="issueDate" @input="menu3 = false" name="Date of issue"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="province" dense outlined v-model="pro" ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field label="School name" dense outlined v-model="school" v-validate="'required'" name="School name"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Section" dense outlined v-model="section" v-validate="'required'" name="Section"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field label="Option" dense outlined v-model="option" v-validate="'required'" name="Option"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Code" dense outlined v-model="code" v-validate="'required|numeric'" name="Code" @keyup="disableBtnNext"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
            <v-btn
              color="primary"
              @click="studyInfo"
              class="btn-next"
              style="margin-top: 15px;"
              :disabled="btnNext"
            >
              >>
            </v-btn>

            <v-btn color="primary"  class="btn-prev" @click="e1=1" style="margin-top: 15px;"> <<
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="3" style="height: 630px !important; ">
            <v-row v-if="imageShow === 'moveon'">
              <v-col cols="10" style="margin-left: 130px;">
                <Uploader :type="type" :size="1" :url="url" :name="name" @getResponse="receiveResponse($event)" style="height: 100%; margin-bottom: 0 !important;"/>
                <VueFileAgent
                  ref="vueFileAgent"
                  :theme="'list'"
                  :multiple="true"
                  :deletable="true"
                  :meta="true"
                  :accept="'image/*'"
                  :maxSize="'1MB'"
                  :maxFiles="1"
                  :helpText="'Upload your Id or passport photo'"
                  :errorText="{
                    type: 'Invalid file type. Only images Allowed',
                    size: 'Files should not exceed 1MB in size',
                  }"
                  @select="filesSelected($event)"
                  @beforedelete="onBeforeDelete($event)"
                  @delete="fileDeleted($event)"
                  v-model="fileRecords"
                  style="position:absolute;top: 500px; width: 1007px; height: 120px !important;"
                ></VueFileAgent>
                <v-btn outlined text color="blue" @click="uploadFiles()"
                  style="position:absolute;top: 570px !important;"
                >
                  Upload
                </v-btn>
              </v-col>
            </v-row>
            <div v-if="imageShow === 'appear'" >
              <v-row>
                <v-col cols="6" v-for="image in images" :key="image.id">
                  <v-card
                    class="mx-auto"
                    max-width="500"
                    max-height="250"
                    style="padding: 10px;"
                  >
                    <a target="_blank" :href="'https://uhtgl.qonics.com/qollege/public/assets/uploads/pdf/' + image.document">
                      <v-img
                        id="webviewer" ref="viewer"
                        class="white--text align-end"
                        height="200px"
                        :src="'https://uhtgl.qonics.com/qollege/public/assets/uploads/pdf/pdf.png'"
                        style="width: 200px; height: 190px; margin: 0 0 10px 100px; border-radius: 5px;"
                      >
                      </v-img>
                    </a>
                    <v-card-text class="text--primary">
                      <div>{{image.type}}</div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card
                    class="mx-auto"
                    max-width="500"
                    max-height="250"
                    style="padding: 10px;"
                  >
                    <a target="_blank" :href="'https://uhtgl.qonics.com/qollege/public/assets/uploads/pdf/' + image.document">
                      <v-img
                        class="white--text align-end"
                        height="200px"
                        :src="'https://uhtgl.qonics.com/qollege/public/assets/uploads/pdf/' + image.image"
                        style="width: 460px; border-radius: 5px;"
                      >
                      </v-img>
                    </a>
                    <v-card-text class="text--primary">
                      <div>image</div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-btn
                color="primary"
                @click="e1=4"
                class="btn-next"
                style="margin-top: 15px;"
              >
                >>
              </v-btn>
            </div>
            <v-btn color="primary" class="btn-prev" @click="back" style="position:absolute;top: 590px !important;"> <<
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-card
              class="mb-12"
              color="white"
            >
              <div v-if="choiceVisible === 'choiceNotVisible'">
                <v-row>
                  <v-col cols="3" style="background-color: springgreen; margin: 0;">
                    <img src="@/assets/icons/6.png" style="width: 100%; height: 75%; margin-top: 70px;">
                  </v-col>
                  <v-col cols="9" style="margin-top: 40px;">
                    <img src="@/assets/icons/1.jpg" style="width: 100px; height: 100px; margin-left: 300px;"/>
                    <v-row v-for="(item,index) in rows" :key="item.id">
                      <v-col cols="1"><label class="input-label" v-html="Number(index+1)+''+label[index]"></label> </v-col>
                      <v-col cols="4">
                        <v-select
                          label="Select Faculty"
                          :items="faculty"
                          outlined
                          dense
                          v-model="facultyOne[index]"
                          v-validate="'required'"
                          name="First faculty">
                        </v-select>
                        <span style="color: red;" v-if="valid">{{ errors.first('First faculty') }}</span>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          label="Select department"
                          outlined
                          dense
                          :items="departments[index]"
                          @click="fetchSelectedDepartments(index)"
                          @change="diableFinish"
                          v-model="deptOne[index]"
                          v-validate="'required'"
                          name="First department">
                        </v-select>
                        <span style="color: red;" v-if="valid">{{ errors.first('First department') }}</span>
                      </v-col>
                      <v-col cols="2">
                        <v-btn color="blue" outlined text style="text-transform: none" @click="addRow" v-if="show">Add Choice</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div v-if="choiceVisible === 'choiceVisible'">
                <v-row>
                  <v-col cols="3" style="background-color: springgreen; margin: 0;">
                    <img src="@/assets/icons/6.png" style="width: 100%; height: 75%; margin-top: 70px;">
                  </v-col>
                  <v-col cols="9" style="margin-top: 40px;">
                    <img src="@/assets/icons/1.jpg" style="width: 100px; height: 100px; margin-left: 300px;"/>
                    <div v-for="(choice,index) in choiceInfo" :key="choice.id">
                      <v-row>
                        <v-col cols="1"><label class="input-label" v-html="Number(index+1)+''+label[index]"></label> </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="choice.faculty" outlined dense readonly></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="choice.department" outlined dense readonly></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <v-btn
              color="primary"
              @click="choice"
              style="float: right;"
              :disabled="isDisabled"
              v-if="choiceVisible === 'choiceNotVisible'"
            >
              Finish
            </v-btn>
            <v-btn color="primary" class="btn-prev" @click="back"> <<
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-app>
  </div>
</template>

<script>
import ParentsInfo from './ParentsInfo'
import Uploader from '../Uploader'
import Swal from 'sweetalert2'
import CircleBars from '../../statistics/progress-bars/Widgets/CircleBars'
export default {
  components: {
    Uploader,
    CircleBars,
  },
  // props: { initialDoc: { type: String } },
  data () {
    return {
      initialDoc: '',
      type: 'pdf',
      url: 'upload_pdf',
      name: 'image',
      rows: 1,
      first: 0,
      second: 0,
      third: 0,
      confirmpassword: '',
      password: '',
      sex: null,
      ValidityDate: '',
      menu3: false,
      gender: [
        {
          text: 'Male',
          value: 1,
        },
        {
          text: 'Female',
          value: 2,
        },
      ],
      civil: [
        {
          text: 'Single',
          value: 1,
        },
        {
          text: 'Married',
          value: 2,
        },
        {
          text: 'Divorced',
          value: 3,
        },
      ],
      departments: [],
      e1: 1,
      idNumber: '',
      territory: '',
      province: '',
      district: '',
      phone: '',
      mother: '',
      father: '',
      selected: null,
      //  =======================
      issued: '',
      pro: '',
      diploma: '',
      issueDate: '',
      school: '',
      option: '',
      code: '',
      section: '',
      //= ===========================
      facultyOne: [],
      deptOne: [],
      header: '',
      logger: [],
      intakes: [],
      loginModal: false,
      id: '',
      valid: true,
      faculty: [],
      show: true,
      parentInfo: [],
      studydata: [],
      isVisible: '',
      studyVisible: '',
      load: 'false',
      loading: false,
      loaded: false,
      fileRecords: [],
      uploadUrl: 'https://www.mocky.io/v2/5d4fb20b3000005c111099e3',
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUpload: [],
      imageShow: '',
      images: [],
      imgurl: 'http://dev.Qollege.rw/assets/uploads/pdf/pdf.png',
      label: ['<sup>st</sup> Choice', '<sup>nd</sup> Choice', '<sup>rd</sup> Choice'],
      isDisabled: true,
      choiceInfo: [],
      choiceVisible: '',
      btnNext: true,
      sendingMsg: false,
      image: [],
    }
  },
  created () {
    this.fetchSelectedFacults()
    this.fetchParentsInfo()
    this.fetchStudyInfo()
    this.fetchStudentsImages()
    this.fetchChoiceInfo()
  },
  methods: {
    diableFinish () {
      if (this.deptOne.length === 3) {
        this.isDisabled = false
      } else {
        this.isDisabled = true
      }
    },
    receiveResponse (event) {
      if (event === 'save') {
        this.first = 1
      } else if (event === 'saved') {
        this.second = 1
      } else {
        this.third = 1
      }
    },
    addRow () {
      if (this.rows === 1) {
        this.show = true
      } else {
        this.show = false
      }
      if (this.rows < 3) {
        this.rows += 1
      }
    },
    async fetchSelectedDepartments (index) {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_departments/' + this.facultyOne[index],
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loading = false
          this.departments.push(res.data)
          console.log(res.data + 'array: ' + this.departments[index])
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.loading = false
          this.departments = []
        } else {
          console.log(error)
        }
      }
    },
    async fetchParentsInfo () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_parent_info',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.parentInfo = res.data
          this.isVisible = 'appear'
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.parentInfo = []
          this.isVisible = 'moveon'
        } else {
          console.log(error)
        }
      }
    },
    async fetchStudentsImages () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_student_documents',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.image = res.data.image
          this.images = res.data.documents
          this.imageShow = 'appear'
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.images = []
          this.imageShow = 'moveon'
        } else {
          console.log(error)
        }
      }
    },
    async fetchStudyInfo () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_study_info',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.btnNext = false
          this.studydata = res.data
          this.studyVisible = 'studyVisible'
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.btnNext = true
          this.studydata = []
          this.studyVisible = 'studyNotVisible'
        } else {
          console.log(error)
        }
      }
    },
    async fetchChoiceInfo () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_choices_info',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.choiceInfo = res.data
          this.choiceVisible = 'choiceVisible'
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.choiceInfo = []
          this.choiceVisible = 'choiceNotVisible'
        } else {
          console.log(error)
        }
      }
    },
    back () {
      if (this.e1 === 2) {
        this.e1 = 1
      } else if (this.e1 === 3) {
        this.e1 = 2
      } else if (this.e1 === 4) {
        this.e1 = 3
      }
    },
    modal () {
      const user = localStorage.getItem('logged_user')
      const dash = JSON.parse(user)
      if (dash.login === '0') {
        this.loginModal = true
        this.id = dash.status
      }
    },
    async change_password () {
      if (this.password !== this.confirmpassword) {
        alert('Password not match')
        this.password = ''
        this.confirmpassword = ''
      }
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      const data = {
        id: this.id,
        password: this.password,
      }
      try {
        const res = await this.axios({
          url: 'change_password',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loginModal = false
          this.password = ''
          this.comfirmpassword = ''
          this.$router.push({ name: 'login-page' })
        } else {
          alert('Data not saved')
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (e.response && e.response.status === 400) {
          alert(e.response.data.messages)
          this.names = ''
          this.selected = ''
        } else {
          console.log(e)
        }
      }
    },
    async validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
        } else {
          // form have errors
        }
      })
    },
    async validateStudy () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.studyInfo()
        } else {
          // form have errors
        }
      })
    },
    async validateChoice () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.choice()
        } else {
          // form have errors
        }
      })
    },
    disableBtnNext () {
      if (this.diploma === '' || this.issued === '' || this.issueDate === '' || this.pro === '' || this.school === '' || this.section === '' || this.option === '' || this.code === '') {
        this.btnNext = true
      } else if (this.studyVisible !== 'studyVisible') {
        this.btnNext = false
      } else {
        this.btnNext = false
      }
    },
    async info () {
      this.sendingMsg = true
      this.load = true
      if (this.isVisible === 'appear') {
        this.e1 = 2
      } else {
        if (this.father === '' || this.mother === '' || this.province === '' || this.district === '' || this.territory === '') {
          this.validate()
        } else {
          if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
          this.header = this.logger.accessToken
          const data = {
            father: this.father,
            mother: this.mother,
            idnumber: this.idNumber,
            province: this.province,
            district: this.district,
            territory: this.territory,
            phone: this.phone,
          }
          try {
            const res = await this.axios({
              url: 'manipulate_parent_info',
              method: 'post',
              data: data,
              headers: {
                Authorization: 'Bearer ' + this.header,
              },
            })
            if (res.status === 200) {
              this.sendingMsg = false
              this.load = false
              this.e1 = 2
              this.father = ''
              this.mother = ''
              this.idNumber = ''
              this.province = ''
              this.district = ''
              this.territory = ''
              this.phone = ''
              this.valid = false
            } else {
              alert('Data not saved')
            }
          } catch (e) {
            if (e.response && e.response.status === 401) {
              localStorage.removeItem('logged_user')
              this.$router.push({ name: 'login-page' })
            } else if (e.response && e.response.status === 400) {
              this.sendingMsg = false
              this.load = false
              Swal.fire({
                title: 'Error!',
                text: e.response.data.messages,
                icon: 'error',
                confirmButtonText: 'Ok',
                preConfirm: () => {
                  this.e1 = 2
                  this.father = ''
                  this.mother = ''
                  this.idnumber = ''
                  this.province = ''
                  this.district = ''
                  this.territory = ''
                  this.phone = ''
                  this.valid = false
                },
              })
            } else {
              console.log(e)
            }
          }
        }
      }
    },
    async studyInfo () {
      this.loading = true
      if (this.studyVisible === 'studyVisible') {
        this.e1 = 3
      } else {
        if (this.diploma === '' || this.issued === '' || this.issueDate === '' || this.pro === '' || this.school === '' || this.section === '' || this.option === '' || this.code === '') {
          this.validate()
        } else {
          if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
          this.header = this.logger.accessToken
          const data = {
            diploma: this.diploma,
            issued: this.issued,
            issueDate: this.issueDate,
            province: this.pro,
            school: this.school,
            section: this.section,
            option: this.option,
            code: this.code,
          }
          try {
            const res = await this.axios({
              url: 'manipulate_student_study',
              method: 'post',
              data: data,
              headers: {
                Authorization: 'Bearer ' + this.header,
              },
            })
            if (res.status === 200) {
              this.loading = false
              this.e1 = 3
              this.diploma = ''
              this.issued = ''
              this.issueDate = ''
              this.pro = ''
              this.school = ''
              this.section = ''
              this.option = ''
              this.code = ''
              this.valid = false
            } else {
              alert('Data not saved')
            }
          } catch (e) {
            if (e.response && e.response.status === 401) {
              localStorage.removeItem('logged_user')
              this.$router.push({ name: 'login-page' })
            } else if (e.response && e.response.status === 400) {
              Swal.fire({
                title: 'Error!',
                text: e.response.data.messages,
                icon: 'error',
                confirmButtonText: 'Ok',
                preConfirm: () => {
                  this.e1 = 3
                  this.diploma = ''
                  this.issued = ''
                  this.issueDate = ''
                  this.pro = ''
                  this.school = ''
                  this.section = ''
                  this.option = ''
                  this.code = ''
                  this.valid = false
                },
              })
            } else {
              console.log(e)
            }
          }
        }
      }
    },
    async choice () {
      // if (this.facultyOne === '' || this.deptOne === '') {
      //   alert('It is empty')
      //   this.validateChoice()
      // } else {
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      const data = {
        firstfaculty: this.facultyOne,
        firstdept: this.deptOne,
      }
      try {
        const res = await this.axios({
          url: 'manipulate_student_choice',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loaded = false
          this.facultyOne = ''
          this.deptOne = ''
          this.valid = false
          Swal.fire({
            title: 'You Completed Registration!',
            text: 'We will get back to you via your email for further information',
            icon: 'success',
            confirmButtonText: 'Ok',
            preConfirm: () => {
              window.location.reload()
            },
          })
          this.$router.push({ name: 'dashboard' })
        } else {
          alert('Data not saved')
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else if (e.response && e.response.status === 400) {
          this.loaded = false
          Swal.fire({
            title: 'Error!',
            text: e.response.data.messages,
            icon: 'error',
            confirmButtonText: 'Ok',
            preConfirm: () => {
              this.rows = 1
              this.show = true
              this.e1 = 3
              this.facultyOne = ''
              this.deptOne = ''
              this.valid = false
            },
          })
        } else {
          console.log(e)
        }
      }
      // }
    },
    async fetchSelectedFacults () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_faculty',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.faculty = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    async uploadFiles () {
      if (this.first !== 1 || this.second !== 1 || this.third !== 1) {
        Swal.fire({
          title: 'Error!',
          text: 'Upload all above documents first!!',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      } else {
        if (localStorage.getItem('logged_user')) {
          this.logger = JSON.parse(localStorage.getItem('logged_user'))
        }
        this.header = this.logger.accessToken
        const data = {
          image: this.fileRecords,
        }
        try {
          const res = await this.axios({
            url: 'upload_photo',
            data: data,
            method: 'post',
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            const msg = 'You are successfully uploaded required documents click Ok to finish your registration '
            Swal.fire({
              title: 'Uploaded!',
              text: msg,
              icon: 'success',
              confirmButtonText: 'Ok',
              preConfirm: () => {
                this.e1 = 4
              },
            })
            console.log(res)
          } else {
            alert('Invalid credentials')
          }
        } catch (e) {
          if (e.response && e.response.status === 403) {
            this.loading = false
            this.visible = true
          } else if( e.response && e.response.status === 401) {
            localStorage.removeItem('logged_user')
            this.$router.push({ name: 'login-page' })
          } else {
            console.log(e)
          }
        }
      }
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord)
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error)
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
      console.log(this.fileRecordsForUpload)
      console.log(this.fileRecords)
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1)
        var k = this.fileRecords.indexOf(fileRecord)
        if (k !== -1) this.fileRecords.splice(k, 1)
      } else {
        if (confirm('Are you sure you want to delete?')) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },
  },
}
</script>

<style scoped>
  .btn-next {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    color: #ffffff;
    float: right;
  }

  .btn-prev {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    color: #ffffff;
    float: left;
  }

  .item-container {
    margin: 0;
    width: 100%;
  }

  .logo {
    width: 100px;
    height: 100px;
    float: right;
  }

  .asset-container {
    background-color: #ecf0f5;
    width: 100%;
  }

  .asset-card {
    margin: 40px 250px 0 250px;
    background-color: #ffffff;
    padding: 10px;
    border-top: 4px solid lightgrey;
  }

  .inputs {
    margin: 10px 150px 0 100px;
  }

  .input-label {
    font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
    font-weight: 400;
  }

  @media only screen and (max-width: 1440px) {
    .asset-card {
      margin: 0;
    }
  }

  @media only screen and (max-width: 500px) {
    .asset-card {
      width: 100%;
    }

    .inputs {
      margin: 0;
    }
  }
</style>
