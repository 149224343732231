<template>
  <v-card
    class="mb-12"
    color="white"
  >
<!--    <img src="@/assets/icons/1.jpg" alt="Logo" class="logo">-->
    <v-card>
      <v-row>
        <v-col cols="4" style="background-color: springgreen; margin: 0;">
          <img src="@/assets/icons/3.png" style="width: 100%; height: 75%; margin-top: 70px;">
        </v-col>
        <v-col cols="5" style="margin-top: 40px;">
          <center><img src="@/assets/icons/1.jpg" style="width: 100px; height: 100px;"></center>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Father's names" dense outlined v-model="father" name="Father name"></v-text-field>
              <span style="color: red;">{{ errors.first('Father name') }}</span>
            </v-col>
            <v-col cols6>
              <v-text-field label="Mother's names" dense outlined v-model="father" v-validate="'required'" name="Mother names"></v-text-field>
              <span style="color: red;">{{ errors.first('Mother name') }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field label="District" dense outlined  v-model="district" v-validate="'required'" name="District"></v-text-field>
              <span style="color: red;">{{ errors.first('District') }}</span>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Territory" dense outlined v-model="territory" v-validate="'required'" name="Territory"></v-text-field>
              <span style="color: red;">{{ errors.first('Territory') }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Province" dense outlined v-model="province" v-validate="'required'" name="Province"></v-text-field>
              <span style="color: red;">{{ errors.first('Province') }}</span>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Guardian phone number" dense outlined v-model="phone" v-validate="'required|numeric'" name="Guardian phone"></v-text-field>
              <span style="color: red;">{{ errors.first('Guardian phone') }}</span>
            </v-col>
          </v-row>
          <v-text-field label="Guardian Id number" dense outlined v-model="idNumber" v-validate="'required|numeric'" name="Guardian Identity"></v-text-field>
          <span style="color: red;">{{ errors.first('Guardian Identity') }}</span>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: 'ParentsInfo',
  props: {
    father: {
      type: String,
      default: null,
    },
    mother: {
      type: String,
      default: null,
    },
    district: {
      type: String,
      default: null,
    },
    territory: {
      type: String,
      default: null,
    },
    province: {
      type: String,
      default: null,
    },
    phone: {
      type: Number,
      default: 0,
    },
    idNumber: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
